/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import { useEffect, FC, ReactNode, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Router, useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import NProgress from 'nprogress';
import { track } from '@amplitude/analytics-browser';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { SessionProvider as AuthProvider } from 'next-auth/react';
import { ekoWebPixel } from '@ekolabs/eko-gallery-react';
import useBuildStore, {
  getSetPrevPath,
  getSetCurrentPath,
  getCurrentPath,
} from '@stores/use-build-store';
import parseLocale from '@utils/parse-locale';
import detectAdblock from '@utils/detect-adblock';
import { HistoryProvider } from '@utils/history';
import { CommerceProvider } from '@framework';
import { useLocationBasedCookies, useRegionFeatures } from '@utils/hooks';
import { getRegion } from '@framework/api/utils/maxify';
import CookieControl from '@components/CookieControl';
import YotpoReviews from '@components/YotpoReviews';
import {
  SEO_DEFAULTS,
  DEFAULT_LOCALE,
  ROUTE_BUILD,
  ROUTE_BUILD_PC,
  ROUTE_PRODUCT,
  ROUTE_CART,
} from '@constants';
import * as locales from '@locale';
import '@css/fonts.css';
import '@css/index.css';
import ExtendConfig from '@components/Extend/ExtendConfig';
import InitializeAftermarket from '@components/Extend/ExtendAfterMarket';
import Adoric from '@components/Adoric';
import Impact from '@components/Impact';
import Amplitude from '@components/Amplitude';
import ThreadAi from '@components/ThreadAi';
import { ThreadAiPixel } from '@components/ThreadAi/ThreadAiComponents';
import FragileDynamicPriceLoader from '../fragile/components/FragileDynamicPriceLoader';

NProgress.configure({ showSpinner: false });

const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV;
const cookieControlId = process.env.NEXT_PUBLIC_COOKIE_CONTROL_ID;
const cookieControlSrc = process.env.NEXT_PUBLIC_COOKIE_CONTROL_SRC;
const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
const gtmServerId = process.env.NEXT_PUBLIC_SSGTM_URL;

const Noop: FC<{ children?: ReactNode }> = ({ children }) => <>{children}</>;

const MyApp: FC<AppProps> = ({ Component, pageProps, ...rest }) => {
  const router = useRouter();
  const { locale, defaultLocale, pathname } = router;
  const defLocale = defaultLocale || DEFAULT_LOCALE;
  const parsedLocale = parseLocale(defLocale, locale);
  const lang = parsedLocale[0];
  const region = getRegion(locale);

  const { zendeskChat: showZendeskChat } = useRegionFeatures(region);

  const ZendeskChatComponent = useMemo(() => {
    if (showZendeskChat) {
      const ChatComponent = dynamic(
        () => import('@components/ZendeskChat/ZendeskChatFlowbuilder')
      );
      return ChatComponent;
    }
    return Noop;
  }, [showZendeskChat]);

  const {
    locationBasedCookies: { cartCookie, localeCookie, shopifyCartCookie },
    updateLocationBasedCookies,
  } = useLocationBasedCookies({ locale, defaultLocale });

  // eslint-disable-next-line import/namespace
  const localeCopy = locales[lang];
  const messages = localeCopy[pathname] || localeCopy.universal;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Layout = (Component as any).Layout || Noop;

  // detect adblock
  useEffect(() => {
    async function detect(): Promise<void> {
      const usingBlocker = await detectAdblock();
      window.blocker = usingBlocker;
    }

    detect();
  }, []);

  // initial pageview event for Amplitude
  useEffect(() => {
    const initialLoadSet = sessionStorage.getItem('initialLoad');
    const initialUrlSet = sessionStorage.getItem('initialUrl');

    if (!initialUrlSet) {
      sessionStorage.setItem('initialUrl', window.location.href);
    }

    const ampBlArr = [
      ROUTE_PRODUCT,
      ROUTE_CART,
      `${ROUTE_BUILD}/${ROUTE_BUILD_PC}`,
    ];

    const isBlacklisted = ampBlArr.some(u =>
      window.location.pathname.includes(u)
    );

    if (!initialLoadSet && !isBlacklisted) {
      track('Page View', {
        page_url: window.location.href,
        page_title: document.title,
        page_path: window.location.pathname,
        page_domain: window.location.hostname,
        page_location: window.location.href,
      });

      sessionStorage.setItem('initialLoad', 'true');
    }

    window.onbeforeunload = () => {
      sessionStorage.removeItem('initialLoad');
    };

    return () => sessionStorage.removeItem('initialLoad');
  }, []);

  // initialize the eko web pixel
  useEffect(() => {
    ekoWebPixel.init(process.env.NODE_ENV === 'production');
  }, []);

  useEffect(() => {
    const splitUrl = window.location.pathname.split('/');
    const regex = /[a-z]{2}-[A-Z]{2}$/;
    const isLocaleInUrl = splitUrl[1] ? regex.test(splitUrl[1]) : false;
    const localeFromUrl = isLocaleInUrl ? splitUrl[1] : DEFAULT_LOCALE;

    updateLocationBasedCookies(localeFromUrl);
  }, [updateLocationBasedCookies]);

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      const splitUrl = url.split('/');

      const regex = /[a-z]{2}-[A-Z]{2}$/;
      const isLocaleInUrl = splitUrl[1] ? regex.test(splitUrl[1]) : false;
      const localeFromUrl = isLocaleInUrl ? splitUrl[1] : DEFAULT_LOCALE;
      updateLocationBasedCookies(localeFromUrl);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, updateLocationBasedCookies]);

  useEffect(() => {
    const handleRouteChangeStart = (): void => {
      NProgress.start();
    };

    const handleRouteChangeEnd = (): void => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
    };
  }, [router.events]);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { err } = rest as any;

  const setPrevPath = useBuildStore(getSetPrevPath);
  const setCurrentPath = useBuildStore(getSetCurrentPath);
  const currentPath = useBuildStore(getCurrentPath);

  useEffect(() => {
    if (router.asPath !== currentPath) {
      if (currentPath && !currentPath.startsWith(`/${ROUTE_BUILD}`)) {
        setPrevPath(currentPath);
      }
      setCurrentPath(router.asPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      person_profiles: 'always',
      loaded: ph => {
        if (process.env.NODE_ENV === 'development') ph.debug();
      },
      opt_out_capturing_by_default: true,
    });

    const handleRouteChange = (): any => posthog?.capture('$pageview');

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <AuthProvider session={pageProps.session}>
      <PostHogProvider client={posthog}>
        <FragileDynamicPriceLoader />
        <IntlProvider
          locale={locale}
          defaultLocale={defaultLocale}
          messages={messages}
        >
          <HistoryProvider>
            <DefaultSeo {...SEO_DEFAULTS} />
            <CommerceProvider
              locale={localeCookie}
              cartCookieValue={cartCookie}
              shopifyCartCookie={shopifyCartCookie}
            >
              <Layout pageProps={pageProps}>
                <Component {...pageProps} err={err} />
                {cookieControlId &&
                  cookieControlSrc &&
                  (vercelEnv === 'production' || vercelEnv === 'preview' ? (
                    <CookieControl
                      scriptSrc={cookieControlSrc}
                      apiKey={cookieControlId}
                      gtmId={gtmId}
                      gtmServerId={gtmServerId}
                      locale={locale}
                    />
                  ) : null)}
                <Adoric />
                {showZendeskChat ? <ZendeskChatComponent lang={lang} /> : null}
                {process.env.NEXT_PUBLIC_FEATURE_ENABLE_REVIEWS === 'true' && (
                  <YotpoReviews />
                )}
                <ExtendConfig />
                <InitializeAftermarket />
              </Layout>
              {process.env.NODE_ENV === 'production' ? <Amplitude /> : null}
              {process.env.NODE_ENV === 'production' ? (
                <>
                  <ThreadAi />
                  <ThreadAiPixel />
                </>
              ) : null}
              <Impact />
            </CommerceProvider>
          </HistoryProvider>
        </IntlProvider>
      </PostHogProvider>
    </AuthProvider>
  );
};

export default MyApp;
