import { SubscriptionLocaleText } from 'fragile/types';

export const FragileEnSubscriptionLocale: SubscriptionLocaleText = {
  subscribe_verb: 'Subscribe',
  subscription_noun: 'Subscription',
  subscribe_cta: 'Subscribe',
  subscription_agreement: 'View Agreement',
  subscription_details: 'NZXT Flex Details',
  subscription_faq: 'NZXT Flex FAQ',
  subscription_pdp_link_text: 'Or',
  subscription_pdp_link_cta: 'subscribe for',
  subscription_pdp_link_cancel: 'Cancel anytime.',
  subscription_pdp_choose_tier: 'Choose Your PC',
  subscription_pdp_choose_color: 'Color',
  subscription_pdp_color_included: 'Included',
  subscription_pdp_nzxt_flex_support: 'Made possible with NZXT Flex',
  subscription_pdp_more_details: 'More Details',
  subscription_pdp_choose_condition: 'Select the Condition',
  subscription_pdp_guaranteed_condition: 'Guaranteed Parts & Performance',
  subscription_pdp_minimum_condition: 'Minimum Parts & Performance',
  subscription_pdp_guaranteed_description:
    'Below are the exact parts for this PC.',
  subscription_pdp_minimum_description:
    'Parts vary but meet or exceed the following:',
  subscription_pdp_avg_fps: 'Average frames per second (FPS)',
  subscription_pdp_min_fps: 'Minimum frames per second (FPS)',
};
